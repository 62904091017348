import React from "react";

export default function TrustedDeviceIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 6H4V18H6C6.28333 18 6.521 18.096 6.713 18.288C6.90433 18.4793 7 18.7167 7 19C7 19.2833 6.90433 19.5207 6.713 19.712C6.521 19.904 6.28333 20 6 20H4C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H19C19.2833 4 19.5207 4.09567 19.712 4.287C19.904 4.479 20 4.71667 20 5C20 5.28333 19.904 5.521 19.712 5.713C19.5207 5.90433 19.2833 6 19 6ZM10 20C9.71667 20 9.47933 19.904 9.288 19.712C9.096 19.5207 9 19.2833 9 19V18.2C8.7 17.9167 8.45833 17.5917 8.275 17.225C8.09167 16.8583 8 16.45 8 16C8 15.55 8.09167 15.1417 8.275 14.775C8.45833 14.4083 8.7 14.0833 9 13.8V13C9 12.7167 9.096 12.479 9.288 12.287C9.47933 12.0957 9.71667 12 10 12H12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13V13.8C13.3 14.0833 13.5417 14.4083 13.725 14.775C13.9083 15.1417 14 15.55 14 16C14 16.45 13.9083 16.8583 13.725 17.225C13.5417 17.5917 13.3 17.9167 13 18.2V19C13 19.2833 12.9043 19.5207 12.713 19.712C12.521 19.904 12.2833 20 12 20H10ZM11 17.5C11.4333 17.5 11.7917 17.3583 12.075 17.075C12.3583 16.7917 12.5 16.4333 12.5 16C12.5 15.5667 12.3583 15.2083 12.075 14.925C11.7917 14.6417 11.4333 14.5 11 14.5C10.5667 14.5 10.2083 14.6417 9.925 14.925C9.64167 15.2083 9.5 15.5667 9.5 16C9.5 16.4333 9.64167 16.7917 9.925 17.075C10.2083 17.3583 10.5667 17.5 11 17.5ZM16 20C15.7167 20 15.4793 19.904 15.288 19.712C15.096 19.5207 15 19.2833 15 19V10C15 9.71667 15.096 9.479 15.288 9.287C15.4793 9.09567 15.7167 9 16 9H21C21.2833 9 21.5207 9.09567 21.712 9.287C21.904 9.479 22 9.71667 22 10V19C22 19.2833 21.904 19.5207 21.712 19.712C21.5207 19.904 21.2833 20 21 20H16ZM17 18H20V11H17V18Z"
        fill="white"
      />
    </svg>
  );
}
