import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";

import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { DOCS } from "../../../utils/constants";
import { AUTO_REFRESH_MEASURE } from "../../../utils/io";

import { Protected } from "../../Authz/Protected";
import ButtonWithTooltip from "../../ButtonWithTooltip";
import CustomExportButton from "../CustomExportButton";
import BindDevicesButton from "./BindDevicesButton";
import BlockControlButton from "./BlockControlButton";
import UnbindDevicesButton from "./UnbindDevicesButton";

export function MeasureToolbarButton(props) {
  const { text, disabled, ...buttonProps } = props;

  return (
    <Button
      sx={{
        padding: "5px 10px",
        textTransform: "capitalize",
      }}
      variant="outlined"
      disabled={disabled}
      {...buttonProps}
    >
      {text}
    </Button>
  );
}

MeasureToolbarButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

MeasureToolbarButton.defaultProps = {
  disabled: false,
};

function MeasureListToolbar(props) {
  const {
    handleResend,
    isResend,
    setAutoRefreshMeasure,
    autoRefreshMeasure,
    isDisabled,
    visibleToolbarItems,
    csvExportProps,
    customComponents,
    bindDevicesButtonProps,
    blockDevicesButtonProps,
    unbindDevicesButtonProps,
    handleApprove,
    handleReject,
  } = props;

  const onChangeAutoRefresh = (e) => {
    setAutoRefreshMeasure(e.target.checked);
    localStorage.setItem(AUTO_REFRESH_MEASURE, e.target.checked);
  };

  useEffect(() => {
    const storedValue = JSON.parse(
      localStorage.getItem(AUTO_REFRESH_MEASURE) || "false"
    );
    setAutoRefreshMeasure(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="row" justifyContent="space-between">
      <GridToolbarContainer>
        {visibleToolbarItems.resend && (
          <Protected permission="ResendMeasures">
            <ButtonWithTooltip
              description={DOCS.RESEND_MEASURE.description}
              url={DOCS.RESEND_MEASURE.url}
              onClick={handleResend}
              startIcon={isResend && <CircularProgress size={16} />}
              disabled={isDisabled}
              sx={{
                padding: "5px 10px",
                mr: "10px",
                textTransform: "capitalize",
              }}
              title={isResend ? "Resending" : "Resend"}
            />
          </Protected>
        )}

        {visibleToolbarItems.bindDevices && (
          <Protected permission="BindDevices">
            <BindDevicesButton
              {...bindDevicesButtonProps}
              disabled={isDisabled}
            />
          </Protected>
        )}

        {visibleToolbarItems.unbindDevices && (
          <Protected permission="UnbindDevices">
            <UnbindDevicesButton
              {...unbindDevicesButtonProps}
              disabled={isDisabled}
            />
          </Protected>
        )}

        {visibleToolbarItems.blockDevices && (
          <Protected permission="BlockDevices">
            <BlockControlButton
              disabled={isDisabled}
              {...blockDevicesButtonProps}
            />
          </Protected>
        )}

        {visibleToolbarItems.unblockDevices && (
          <Protected permission="BlockDevices">
            <BlockControlButton
              disabled={isDisabled}
              blockDevices={false}
              buttonText="Unblock Devices"
              {...blockDevicesButtonProps}
            />
          </Protected>
        )}

        {visibleToolbarItems.export && (
          <CustomExportButton disabled={isDisabled} {...csvExportProps} />
        )}

        {visibleToolbarItems.approveMeasures && (
          <Protected permission="Approve">
            <ButtonWithTooltip
              description={DOCS.APPROVE_MEASURES.description}
              url={DOCS.APPROVE_MEASURES.url}
              onClick={handleApprove}
              disabled={isDisabled}
              variant="contained"
              sx={{
                padding: "5px 10px",
                mr: "10px",
              }}
              title="Approve"
            />
          </Protected>
        )}
        {visibleToolbarItems.rejectMeasures && (
          <Protected permission="Reject">
            <ButtonWithTooltip
              description={DOCS.REJECT_MEASURES.description}
              url={DOCS.REJECT_MEASURES.url}
              onClick={handleReject}
              disabled={isDisabled}
              variant="contained"
              color="error"
              sx={{
                padding: "5px 10px",
                mr: "10px",
              }}
              title="Reject"
            />
          </Protected>
        )}
        {customComponents.map((component) => (
          <Box key={component.name} sx={{ mr: "10px" }}>
            {component.value}
          </Box>
        ))}
      </GridToolbarContainer>

      {/* Auto Refresh Element Toolbar */}
      {visibleToolbarItems.autoRefresh && (
        <GridToolbarContainer>
          <Stack sx={{ flexDirection: "row" }}>
            <Tooltip
              title={
                <Typography variant="body2">
                  Refresh measurements every 10 seconds
                </Typography>
              }
              arrow
            >
              <FormControlLabel
                control={<Switch checked={autoRefreshMeasure} />}
                onChange={onChangeAutoRefresh}
                label="Auto Refresh"
                labelPlacement="start"
              />
            </Tooltip>
          </Stack>
        </GridToolbarContainer>
      )}
    </Stack>
  );
}

MeasureListToolbar.propTypes = {
  handleResend: PropTypes.func,
  isResend: PropTypes.bool,
  autoRefreshMeasure: PropTypes.bool,
  setAutoRefreshMeasure: PropTypes.func,
  isDisabled: PropTypes.bool,
  csvExportProps: PropTypes.shape({
    useDefaultCsvExport: PropTypes.bool,
    exportDataGetter: PropTypes.func,
    fileName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
  visibleToolbarItems: PropTypes.shape({
    resend: PropTypes.bool,
    bindDevices: PropTypes.bool,
    unbindDevices: PropTypes.bool,
    blockDevices: PropTypes.bool,
    unblockDevices: PropTypes.bool,
    export: PropTypes.bool,
    autoRefresh: PropTypes.bool,
    rejectMeasures: PropTypes.bool,
    approveMeasures: PropTypes.bool,
  }),
  customComponents: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, value: PropTypes.node })
  ),
  bindDevicesButtonProps: PropTypes.shape(BindDevicesButton.propTypes),
  blockDevicesButtonProps: PropTypes.shape(BlockControlButton.propTypes),
  unbindDevicesButtonProps: PropTypes.shape(UnbindDevicesButton.propTypes),
  handleApprove: PropTypes.func,
  handleReject: PropTypes.func,
};
MeasureListToolbar.defaultProps = {
  handleResend: () => {},
  isResend: false,
  autoRefreshMeasure: false,
  setAutoRefreshMeasure: () => {},
  isDisabled: false,
  csvExportProps: {},
  visibleToolbarItems: {},
  customComponents: [],
  bindDevicesButtonProps: BindDevicesButton.defaultProps,
  blockDevicesButtonProps: BlockControlButton.defaultProps,
  unbindDevicesButtonProps: UnbindDevicesButton.defaultProps,
  handleApprove: () => {},
  handleReject: () => {},
};
export default MeasureListToolbar;
