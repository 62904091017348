import { Clear } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { MEASURE_TYPES } from "../../utils/constants";
import { get, post } from "../../utils/io";
import ContractIcon from "../icons/ContractIcon";
import StelDataGrid from "../StelDataGrid";
import LoadingHub from "./LoadingHub";

const POLLING_INTERVAL = 5000;

function TrustedDevice(props) {
  const { hubId } = useParams();
  const timeoutRef = useRef(null);

  const { isOpen, handleTrustedDevice } = props;
  const [listTrustedDevices, setListTrustedDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    totalCount: 0,
  });
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [loadingDiscoveryDevice, setLoadingDiscoveryDevice] = useState(false);

  const columns = [
    { field: "mac_address", headerName: "MAC Address", flex: 1 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      valueGetter: (params) =>
        MEASURE_TYPES[params?.value]?.label || params.row.type,
    },
    {
      field: "is_blocked",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {params.row.is_blocked ? (
            <ClearIcon color="error" />
          ) : (
            <CheckIcon color="success" />
          )}
        </Box>
      ),
      flex: 0.5,
    },
    {
      field: "payload_schema",
      headerName: "Last Measurement",
      valueGetter: (params) => JSON.stringify(params.row?.last_measure),
      flex: 1,
    },
  ];

  const getDevicesFromRow = (ids) =>
    (listTrustedDevices || [])
      .filter((device) => ids.includes(device.id))
      .map((device) => device.mac_address);

  const handleSelectRow = (ids) => {
    const row = getDevicesFromRow(ids);
    setSelectedDevice(row || []);
  };

  const fetchTrustedDevices = async () => {
    const res = await get("/trusted_devices", {
      hub_id: hubId.substring(0, 10),
      offset: pagination.page,
      limit: pagination.pageSize,
    });
    return res;
  };

  const getListTrustedDevices = async () => {
    setLoading(true);
    const res = await fetchTrustedDevices();
    setPagination((pre) => ({
      ...pre,
      totalCount: res.data?.total_count || 0,
    }));
    setLoading(false);
    setLoadingDiscoveryDevice(res.data.is_discovering);
    if (res.data.is_discovering && isOpen) {
      timeoutRef.current = setTimeout(getListTrustedDevices, POLLING_INTERVAL);
    }

    setListTrustedDevices(res.data.devices);
  };

  const handleDiscoveryDevice = async () => {
    setLoadingDiscoveryDevice(true);
    await get(`hubs/${hubId}/discover_new_device`, { timeout: -1 });
    const fetchDevices = async () => {
      const res = await fetchTrustedDevices();
      return res.data;
    };
    const checkDevices = async () => {
      const dataDevice = await fetchDevices();
      if (
        dataDevice?.total_count &&
        dataDevice.total_count - pagination.totalCount > 0
      ) {
        enqueueSnackbar(
          `We have found ${
            dataDevice.total_count - pagination.totalCount
          } devices.`,
          {
            variant: "success",
          }
        );
        await getListTrustedDevices();

        return;
      }
      setLoadingDiscoveryDevice(dataDevice.is_discovering);
      timeoutRef.current = setTimeout(checkDevices, POLLING_INTERVAL);
    };

    checkDevices();
  };

  const handleBlockDevices = async () => {
    const res = await post("/trusted_devices", {
      hub_id: hubId.substring(0, 10),
      mac_addresses: selectedDevice,
    });
    if (res.status === 204) {
      enqueueSnackbar(`Successfully blocked device(s).`, {
        variant: "success",
      });
      getListTrustedDevices();
      return;
    }
    enqueueSnackbar(`Failed to block device(s).`, {
      variant: "error",
    });
  };

  useEffect(() => {
    if (!isOpen) return;
    getListTrustedDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubId, isOpen, pagination.page, pagination.pageSize]);

  useEffect(() => {
    if (!isOpen && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [isOpen]);

  return (
    <Stack>
      <Drawer anchor="right" open={isOpen} onClose={handleTrustedDevice}>
        <Stack width={700}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 16px",
              borderBottom: "1px solid #0000003B",
            }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
              Trusted Devices
            </Typography>
            <Button onClick={handleTrustedDevice} sx={{ minWidth: 0 }}>
              <Clear sx={{ color: "#000" }} />
            </Button>
          </Box>
          <Stack sx={{ padding: "24px 16px 12px 16px", gap: "16px" }}>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                TRUSTED DEVICE LIST
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  disabled={!selectedDevice?.length || loading}
                  variant="outlined"
                  onClick={handleBlockDevices}
                >
                  Block Device
                </Button>
                <Button
                  disabled={loadingDiscoveryDevice}
                  variant="contained"
                  onClick={handleDiscoveryDevice}
                >
                  Discovery Device
                </Button>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                justifyContent: "center",
              }}
            >
              {loadingDiscoveryDevice && (
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  <LoadingHub />
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 500, color: "#636D76" }}
                  >
                    Discovering the devices.
                  </Typography>
                </Stack>
              )}
              {listTrustedDevices && !listTrustedDevices?.length && !loading ? (
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  <ContractIcon />
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 500, color: "#636D76" }}
                  >
                    No devices.
                  </Typography>
                </Stack>
              ) : (
                <StelDataGrid
                  rows={listTrustedDevices || []}
                  columns={columns}
                  loading={loading}
                  checkboxSelection
                  pageSize={pagination.pageSize}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  rowCount={pagination.totalCount}
                  onSelectionModelChange={(ids) => handleSelectRow(ids)}
                  onPageSizeChange={(pageSize) =>
                    setPagination((pre) => ({ ...pre, pageSize }))
                  }
                  onPageChange={(page) =>
                    setPagination((pre) => ({ ...pre, page }))
                  }
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
}

export default TrustedDevice;

TrustedDevice.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleTrustedDevice: PropTypes.func.isRequired,
};
