import AddIcon from "@mui/icons-material/Add";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import classNames from "classnames";
import { differenceWith, uniq } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { HUB_RETURN_REASONS } from "../../utils/constants";
import { get, requestV2 } from "../../utils/io";
import BulkHubsActionModal from "../BulkHubsActionModal";
import CreatableAutocomplete from "../CreatableAutocomplete";
import StelDataGrid from "../StelDataGrid";
import useStyles from "./styles";

function ListHubReturn(props) {
  const classes = useStyles();
  const { onRemoveHub, hubs, updateHubs, disabled } = props;

  const [input, setInput] = useState({
    hub_id: null, // must default to null for Autocomplete
    reason: "",
    reason_detail: "",
  });
  const [listHubs, setListHubs] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [inputHub, setInputHub] = useState("");
  const [detailOptions, setDetailOptions] = useState([]);

  useEffect(() => {
    const getHubs = async () => {
      const res = await get("/hubs", { hub_id: inputHub, limit: 10 });
      if (res.status !== 200)
        return enqueueSnackbar("Unable to retrieve hubs", { variant: "error" });

      return setListHubs(res.data.hubs.map((hub) => hub.hub_id));
    };
    getHubs();
  }, [inputHub]);

  useEffect(
    () =>
      setDetailOptions(
        // Get existing reason details to use as options and filter out any empty ones
        uniq(hubs.map((hub) => hub.reason_detail).filter((option) => option))
      ),
    [hubs]
  );

  const onChangeRowHub = (row, value) => {
    const existingHub = hubs.find((hub) => hub.hub_id === row.hub_id);
    Object.assign(existingHub, { ...existingHub, ...value });
    updateHubs(hubs);
  };

  const handleOpenModal = () => setIsModal(true);

  const handleCloseModal = () => setIsModal(false);

  const handleBulkAddHubs = async (hubIds) => {
    try {
      // Filter duplicate hub ids
      const hubsToAdd = differenceWith(hubIds, hubs, (a, b) =>
        b.hub_id.startsWith(a)
      );
      if (hubsToAdd.length !== 0) {
        const res = await requestV2("post", "/bulk/hubs", {
          data: { hub_ids: hubsToAdd },
        });
        updateHubs([
          ...hubs,
          ...res.data.hub_ids.map((hubId) => ({
            hub_id: hubId,
            reason: "",
            reason_detail: "",
          })),
        ]);
      }
      handleCloseModal();
    } catch (err) {
      enqueueSnackbar(
        `The following hubs were not found: ${err.response.data.unknown_hubs.join(
          ", "
        )}`,
        // Using persistent snackbar to allow user time to see all hubs in error message
        { variant: "error", persist: true }
      );
    }
  };

  const columns = [
    {
      field: "hub_id",
      headerName: "Hub Id",
      flex: 0.75,
      renderCell: (params) => params.row.hub_id.substring(0, 10),
    },
    {
      field: "reason",
      headerName: "Reason*",
      flex: 1,
      renderCell: (params) => (
        <TextField
          onChange={(e) =>
            onChangeRowHub(params.row, { reason: e.target.value })
          }
          select
          fullWidth
          size="small"
          value={params.value}
          disabled={disabled}
        >
          {Object.keys(HUB_RETURN_REASONS).map((reason) => (
            <MenuItem key={reason} value={HUB_RETURN_REASONS[reason]}>
              {HUB_RETURN_REASONS[reason]}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: "reason_detail",
      headerName: "Detail*",
      flex: 1.5,
      renderCell: (params) => (
        <CreatableAutocomplete
          value={params.value}
          options={detailOptions}
          onChange={(value) =>
            onChangeRowHub(params.row, { reason_detail: value || "" })
          }
          onAddOption={(option) => setDetailOptions((pre) => [...pre, option])}
          size="small"
        />
      ),
    },
    ...(!disabled
      ? [
          {
            field: "actions",
            headerName: "",
            flex: 0.5,
            renderCell: (params) => (
              <Button
                color="error"
                variant="outlined"
                onClick={onRemoveHub(params.id)}
                size="small"
              >
                Remove
              </Button>
            ),
          },
        ]
      : []),
  ];

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const onAddHub = () => {
    if (input.hub_id.length < 10)
      return enqueueSnackbar("Hub IDs must be at least 10 characters", {
        variant: "error",
      });
    if (hubs.some((hub) => hub.hub_id === input.hub_id))
      return enqueueSnackbar("Hub ID already added", { variant: "error" });
    setInputHub("");
    setInput({
      hub_id: null,
      reason: "",
      reason_detail: "",
    });
    return updateHubs([...hubs, input]);
  };

  return (
    <div>
      <div className={classes.hubReturnTitle}>Hubs to Return</div>
      {!disabled && (
        <div className={classNames(classes.flex, classes.wrap)}>
          <Autocomplete
            sx={{ width: 200 }}
            className={classNames(classes.mr16, classes.mb16)}
            size="small"
            options={listHubs}
            getOptionLabel={(option) => option?.substring(0, 10) || ""}
            value={input.hub_id}
            onChange={(_, hubId) => {
              setInput((pre) => ({ ...pre, hub_id: hubId.trim() || null }));
            }}
            inputValue={inputHub}
            onInputChange={(_, value) => setInputHub(value.trim())}
            renderInput={(params) => (
              <TextField {...params} required label="Hub ID" />
            )}
          />
          <TextField
            onChange={handleChangeInput}
            sx={{ minWidth: 200 }}
            value={input.reason}
            label="Reason"
            name="reason"
            select
            size="small"
            className={classNames(classes.mr16, classes.mb16)}
            required
          >
            {Object.keys(HUB_RETURN_REASONS).map((reason) => (
              <MenuItem key={reason} value={HUB_RETURN_REASONS[reason]}>
                {HUB_RETURN_REASONS[reason]}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            value={input.reason_detail}
            sx={{ minWidth: 400 }}
            onChange={handleChangeInput}
            label="Detail"
            name="reason_detail"
            size="small"
            className={classNames(classes.mb16, classes.mr16)}
            required
          />
          <Button
            onClick={onAddHub}
            variant="contained"
            className={classNames(classes.mr16, classes.mb16)}
            startIcon={<AddIcon />}
            disabled={!input.hub_id}
          >
            Add
          </Button>
          <Button
            variant="contained"
            startIcon={<LibraryAddCheckOutlinedIcon />}
            className={classNames(classes.mr16, classes.mb16)}
            onClick={handleOpenModal}
          >
            Bulk Add
          </Button>
        </div>
      )}
      <StelDataGrid
        rows={hubs}
        columns={columns}
        rowHeight={56}
        getRowId={(row) => row.hub_id}
        onCellKeyDown={(params, event) => {
          // Prevent tabbing events with spacebar/arrow keys when typing in reason
          // detail column
          if (params.field === "reason_detail") event.stopPropagation();
        }}
      />
      <BulkHubsActionModal
        isVisible={isModal}
        onClose={handleCloseModal}
        onSubmit={handleBulkAddHubs}
      />
    </div>
  );
}

ListHubReturn.propTypes = {
  onRemoveHub: PropTypes.func.isRequired,
  updateHubs: PropTypes.func.isRequired,
  hubs: PropTypes.arrayOf(
    PropTypes.objectOf((PropTypes.number, PropTypes.string))
  ).isRequired,
  disabled: PropTypes.bool,
};

ListHubReturn.defaultProps = {
  disabled: false,
};

export default ListHubReturn;
