import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Box,
} from "@mui/material";
import { endOfDay, sub } from "date-fns";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FEATURE_FLAGS } from "../../../utils/constants";
import { getFeatureFlag } from "../../../utils/io";

const getMeasureStatus = () => [
  { value: "no endpoints" },
  { value: "succeeded" },
  { value: "failed" },
  { value: "in progress" },
  ...(getFeatureFlag(FEATURE_FLAGS.CLINICAL_VALIDATION)
    ? [{ value: "pending clinical validation" }, { value: "clinical rejected" }]
    : []),
];

const getOneMonthAgo = () => sub(new Date(), { months: 1 });
const getEndOfDay = () => endOfDay(new Date());
const emptySearchInputs = {
  macAddress: "",
  hubId: "",
  status: "",
  takenAfterDate: getOneMonthAgo(),
  takenBeforeDate: getEndOfDay(),
  strictPairHub: true,
};

function MeasureSearchForm(props) {
  const {
    onSubmit,
    isSearchDisabled,
    searchCriterias,
    defaultSearchInputs,
    statusPendingValidation,
  } = props;

  const baseSearchInputs = { ...emptySearchInputs, ...defaultSearchInputs };
  const [searchInputs, setSearchInputs] = useState({ ...baseSearchInputs });

  const onChangeSearchInputs = (e) => {
    const { name, value, type, checked } = e.target;
    setSearchInputs({
      ...searchInputs,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    onSubmit({ ...searchInputs });
  };

  const onClearFilter = async (e) => {
    e.preventDefault();
    setSearchInputs({ ...baseSearchInputs });
    onSubmit({ ...baseSearchInputs });
  };

  return (
    <form style={{ marginBottom: 20 }} onSubmit={onFormSubmit}>
      <Box
        sx={{
          display: "flex",
          marginBottom: "15px",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {searchCriterias.hubId && (
          <TextField
            label="Hub ID"
            name="hubId"
            onChange={onChangeSearchInputs}
            value={searchInputs.hubId}
            size="small"
            variant="standard"
          />
        )}
        {searchCriterias.strictPairHub && (
          <Tooltip title="Enable to filter strictly by Paired Hub ID. Disable to expand filtering to include Transmiting Hub ID.">
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchInputs.strictPairHub}
                  onChange={onChangeSearchInputs}
                  name="strictPairHub"
                  size="small"
                  disabled={!searchInputs.hubId && searchCriterias.hubId}
                />
              }
              label="Strict Paired Hub Filter"
            />
          </Tooltip>
        )}
      </Box>
      <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
        {searchCriterias.macAddress && (
          <TextField
            label="MAC Address"
            name="macAddress"
            onChange={onChangeSearchInputs}
            value={searchInputs.macAddress}
            size="small"
            variant="standard"
          />
        )}
        {searchCriterias.status && (
          <FormControl
            sx={{
              minWidth: "150px",
            }}
            size="small"
            variant="standard"
          >
            <InputLabel>Delivery Status</InputLabel>
            <Select
              labelId="select-label"
              value={statusPendingValidation || searchInputs.status}
              label="Delivery Status"
              name="status"
              disabled={statusPendingValidation}
              sx={{ textTransform: "capitalize" }}
              onChange={onChangeSearchInputs}
            >
              {getMeasureStatus().map((item) => (
                <MenuItem
                  value={item.value}
                  sx={{ textTransform: "capitalize" }}
                  key={item.value}
                >
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="MM/dd/yyyy"
            label="Taken After"
            maxDate={new Date()}
            name="takenAfterDate"
            onChange={(val) =>
              setSearchInputs({ ...searchInputs, takenAfterDate: val })
            }
            value={searchInputs.takenAfterDate}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ marginRight: "15px" }}
                size="small"
                variant="standard"
              />
            )}
          />
          <DatePicker
            format="MM/dd/yyyy"
            label="Taken Before"
            maxDate={new Date()}
            name="takenBeforeDate"
            onChange={(val) =>
              setSearchInputs({ ...searchInputs, takenBeforeDate: val })
            }
            value={searchInputs.takenBeforeDate}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ marginRight: "15px" }}
                size="small"
                variant="standard"
              />
            )}
          />
        </LocalizationProvider>
        <Button
          sx={{ marginRight: "15px" }}
          color="primary"
          type="submit"
          variant="contained"
          disabled={isSearchDisabled}
        >
          Search
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={
            !searchInputs.hubId &&
            !searchInputs.macAddress &&
            !searchInputs.status &&
            baseSearchInputs.strictPairHub === searchInputs.strictPairHub
          }
          onClick={onClearFilter}
        >
          Clear
        </Button>
      </Box>
    </form>
  );
}

MeasureSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSearchDisabled: PropTypes.bool,
  searchCriterias: PropTypes.objectOf(PropTypes.bool),
  defaultSearchInputs: PropTypes.shape({
    takenAfterDate: PropTypes.instanceOf(Date),
  }),
  statusPendingValidation: PropTypes.string,
};

MeasureSearchForm.defaultProps = {
  isSearchDisabled: false,
  searchCriterias: {
    hubId: true,
    macAddress: true,
    status: true,
    strictPairHub: false,
  },
  defaultSearchInputs: {},
  statusPendingValidation: null,
};

export default MeasureSearchForm;
