import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
  Stack,
  Link,
} from "@mui/material";
import { red, grey } from "@mui/material/colors";
import { isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DOCS } from "../../utils/constants";
import { get, getFeatureFlag, isAdmin, isOrgUser, post } from "../../utils/io";
import theme from "../../utils/theme";
import { Protected } from "../Authz/Protected";
import DefaultHubGroupIndicator from "../DefaultHubGroupIndicator";
import MainContentContainer from "../Layouts/MainContentContainer";
import StelModal from "../StelModal";
import HubDetailHeader from "./HubDetailHeader";
import HubDevices, { DEVICES_QUERY_CONFIG } from "./HubDevices";
import RecentMeasures from "./RecentMeasures";

function BoldInlineText(value) {
  return <span style={{ fontWeight: 700 }}>{value}</span>;
}

function HubGroupSelect(props) {
  const { onSelectGroup, initialValue } = props;
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    get("/hub_groups").then((hubGroups) => {
      setGroups(hubGroups.data.hub_groups);
    });
  }, []);

  const handleSelectGroup = (e) => {
    const groupSelected = groups.filter(
      (group) => group.id === e.target.value
    )[0];
    onSelectGroup(groupSelected);
  };

  return (
    <TextField
      sx={{ minWidth: "200px" }}
      select
      label="Hub Group"
      value={initialValue}
      onChange={handleSelectGroup}
    >
      {groups.map((group) => (
        <MenuItem value={group.id} key={group.id}>
          {group.name} {group.is_default && <DefaultHubGroupIndicator />}
        </MenuItem>
      ))}
    </TextField>
  );
}

HubGroupSelect.propTypes = {
  onSelectGroup: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};

HubGroupSelect.defaultProps = {
  initialValue: "",
};

function HubDetail() {
  const { hubId } = useParams();

  const [hub, setHub] = useState({});
  const [newGroup, setNewGroup] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [measures, setMeasures] = useState([]);
  const [measureSelection, setMeasureSelection] = useState([]);
  const [newDeviceBindingRefresh, setNewDeviceBindingRefresh] = useState({});
  const [autoRefreshMeasure, setAutoRefreshMeasure] = useState(false);

  const bindExpectedDevicesEnabledOnGroup =
    hub?.hub_group?.bind_expected_devices || false;

  const bindExpectedDevicesEnabled =
    getFeatureFlag("bind_expected_devices") &&
    bindExpectedDevicesEnabledOnGroup;

  const showDeviceHoldInfo =
    bindExpectedDevicesEnabled ||
    // Admins should be able to see held devices for a hub
    (bindExpectedDevicesEnabledOnGroup && isAdmin());

  const tabs = {
    boundDevices: { index: 0, label: "Bound Devices" },
    ...(showDeviceHoldInfo && {
      holdDevices: {
        index: 1,
        label: (
          <Stack flexDirection="row" alignItems="center" gap="5px">
            Devices on Hold
            <Link href={DOCS.DEVICE_HOLD.url} target="_blank" rel="noopener">
              {/* Wrapping icon in a flexbox centers it within the link element */}
              <Stack>
                <InfoIcon sx={{ fontSize: 20, color: theme.colors.stelBlue }} />
              </Stack>
            </Link>
          </Stack>
        ),
      },
    }),
    blockedDevices: {
      index: showDeviceHoldInfo ? 2 : 1,
      label: "Blocked Devices",
    },
  };

  const handleSelectGroup = (selectedGroup) => {
    setNewGroup(selectedGroup);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setNewGroup({});
    setErrorMessage("");
  };

  const getHubDetail = useCallback(async () => {
    const hubRes = await get(`/hubs/${hubId}`);
    if (hubRes.response?.status === 404) {
      enqueueSnackbar(`Hub ${hubId} was not found.`, { variant: "error" });
      return;
    }
    setHub(hubRes.data);
  }, [hubId]);

  const handleUpdateGroupId = async () => {
    setErrorMessage("");
    const res = await post(`/hub_groups/${newGroup.id}/hubs`, {
      hub_ids: [hub.hub_id],
    });
    if (res.status === 200) {
      setHub((pre) => ({
        ...pre,
        hub_group: { id: newGroup.id, name: newGroup.name },
      }));
      closeModal();
      getHubDetail();
    } else {
      setErrorMessage("Can not move hub to group.");
    }
  };

  const handleUpdateDevice = () => {
    getHubDetail();
    setNewDeviceBindingRefresh({});
  };

  const getMeasures = useCallback(async () => {
    try {
      const measureRes = await get("/measures", { esn: hubId });
      setMeasures(measureRes.data.measures);
    } catch {
      enqueueSnackbar("Failed to get recent measurements", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [hubId]);

  useEffect(() => {
    const findOpenTicket = async () => {
      const res = await get("/troubleshoot/tickets", {
        hub_id: hubId.substring(0, 10),
        status: "open",
      });
      if (res?.data?.items?.length > 0) setTicket(res.data.items[0]);
    };
    getHubDetail();
    getMeasures();
    findOpenTicket();
  }, [hubId, getHubDetail, getMeasures]);

  useEffect(() => {
    getMeasures();
  }, [autoRefreshMeasure, getMeasures]);

  useEffect(() => {
    if (autoRefreshMeasure) {
      const interval = setInterval(getMeasures, 10000);
      return () => clearInterval(interval);
    }
    return null;
  }, [autoRefreshMeasure, getMeasures]);

  return (
    <MainContentContainer
      title={
        <HubDetailHeader
          hubId={hubId}
          hub={hub}
          showActiveTicketIndicator={!isEmpty(ticket)}
          showExpectingDevicesChip={showDeviceHoldInfo}
        />
      }
      analyticsTitle="Hub Detail"
    >
      {isOrgUser() && (
        <Grid container alignItems="center">
          <Protected permission={["ViewHubGroups", "MoveHubsToHubGroup"]}>
            <HubGroupSelect
              onSelectGroup={handleSelectGroup}
              initialValue={hub?.hub_group?.id || ""}
            />
          </Protected>
        </Grid>
      )}

      <Protected permission="ViewMeasures">
        <RecentMeasures
          loading={loading}
          measures={measures}
          measureSelection={measureSelection}
          setMeasureSelection={setMeasureSelection}
          onChangeBindings={handleUpdateDevice}
          setAutoRefreshMeasure={setAutoRefreshMeasure}
          autoRefreshMeasure={autoRefreshMeasure}
          useTrustedDevices={hub.hub_group?.use_trusted_devices}
        />
      </Protected>

      <Protected permission="ViewDevices">
        <Box
          sx={{
            "& .MuiTabs-flexContainer": {
              borderBottom: `1px solid ${grey[400]}`,
            },
          }}
        >
          <Tabs
            sx={{ pb: "15px", marginTop: "24px" }}
            value={tab}
            onChange={(_, newTab) => setTab(newTab)}
            indicatorColor="primary"
          >
            {Object.values(tabs).map((item) => (
              <Tab key={item.index} label={item.label} />
            ))}
          </Tabs>
        </Box>

        <TabPanel tabIndex={tab} index={tabs.boundDevices.index}>
          <HubDevices
            hubId={hubId}
            onSuccessfulAction={getHubDetail}
            queryConfig={DEVICES_QUERY_CONFIG.BOUND_HUB}
            toolbarButtons={{
              bindDevices: false,
              unbindDevices: true,
              blockDevices: false,
              unblockDevices: false,
            }}
            additionalRefreshDependencies={[newDeviceBindingRefresh]}
          />
        </TabPanel>
        {showDeviceHoldInfo && (
          <TabPanel tabIndex={tab} index={tabs.holdDevices.index}>
            <HubDevices
              hubId={hubId}
              onSuccessfulAction={getHubDetail}
              queryConfig={DEVICES_QUERY_CONFIG.ON_HOLD_HUB}
              toolbarButtons={{
                bindDevices: true,
                unbindDevices: false,
                blockDevices: true,
                unblockDevices: false,
              }}
            />
          </TabPanel>
        )}
        <TabPanel tabIndex={tab} index={tabs.blockedDevices.index}>
          <Typography sx={{ mb: "10px", color: grey[700] }}>
            Showing blocked devices used by {hubId} in the past 6 months.
          </Typography>
          <HubDevices
            hubId={hubId}
            onSuccessfulAction={getHubDetail}
            queryConfig={DEVICES_QUERY_CONFIG.BLOCKED_DEVICES}
            toolbarButtons={{
              bindDevices: false,
              unbindDevices: false,
              blockDevices: false,
              unblockDevices: true,
            }}
            additionalRefreshDependencies={[newDeviceBindingRefresh]}
          />
        </TabPanel>
      </Protected>
      {showModal && (
        <StelModal
          open={showModal}
          onClose={closeModal}
          width={500}
          title="Confirm Hub Group Change"
        >
          <Box sx={{ padding: "20px" }}>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography sx={{ textAlign: "center", marginTop: 0 }}>
                You are about to move hub {BoldInlineText(hubId)}{" "}
                {hub.hub_group.name && "from group"}{" "}
                {BoldInlineText(hub.hub_group.name)} to{" "}
                {BoldInlineText(newGroup.name)}. All measurements coming from
                this hub will now be sent to the following endpoints.
              </Typography>
              {newGroup.endpoints.length === 0 ? (
                <Typography
                  sx={{
                    color: red[500],
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  No endpoints configured for this hub group.
                </Typography>
              ) : (
                <ul>
                  {newGroup.endpoints.map((endpoint) => (
                    <li key={endpoint.id} style={{ fontStyle: "italic" }}>
                      {endpoint.url || "N/A"}
                    </li>
                  ))}
                </ul>
              )}
              {!!errorMessage && (
                <Typography
                  sx={{
                    color: red[500],
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={handleUpdateGroupId}
                color="primary"
                variant="contained"
              >
                Confirm
              </Button>
              <Button onClick={closeModal} color="error" variant="contained">
                Cancel
              </Button>
            </Box>
          </Box>
        </StelModal>
      )}
    </MainContentContainer>
  );
}

function TabPanel(props) {
  const { children, tabIndex, index, ...other } = props;

  return (
    <Box hidden={tabIndex !== index} id={`tab-panel-${index}`} {...other}>
      {tabIndex === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default HubDetail;
