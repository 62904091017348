import React from "react";
import BloodPressure from "../components/icons/BloodPressure";
import Ekg from "../components/icons/Ekg";
import Glucose from "../components/icons/Glucose";
import Inr from "../components/icons/Inr";
import Oximeter from "../components/icons/Oximeter";
import Pillcap from "../components/icons/Pillcap";
import SPO2 from "../components/icons/SpO2";
import Temperature from "../components/icons/Temperature";
import Wearable from "../components/icons/Wearable";
import Weight from "../components/icons/Weight";

export const FEATURE_FLAGS = Object.freeze({
  VERSION_STATUS_FILTER: "version_status_filter",
  DEVICE_REGISTRATION: "device_registration",
  API_PERMISSIONS: "api_permissions",
  TROUBLESHOOT: "troubleshoot",
  CLINICAL_VALIDATION: "clinical_validation",
  MONITORING_PROGRAMS: "monitoring_programs",
  TRUSTED_DEVICES: "trusted_devices",
});

export const DOCS = {
  HUBS: {
    url: "https://stellife.notion.site/Hubs-2f01cacf425c45d49aca643d6197949c",
  },
  MEASURES: {
    url: "https://stellife.notion.site/Measurements-6a9e8e4bd8c64ffc9de9e927267735dd",
  },
  HUB_GROUP: {
    url: "https://stellife.notion.site/Hub-Groups-175e980ccf1b47b4911502c5e7f4a0cc",
    description:
      "All hubs delivered by Stel will be placed in this default hub group prior to delivery. ",
  },
  ENDPOINTS: {
    url: "https://stellife.notion.site/Endpoints-e6d1c72332c745a3a6fa75d105d0b890",
  },
  USERS: {
    url: "https://stellife.notion.site/Users-86e661245e48497ca117cb6883e2f593",
  },
  SIMULATOR: {
    url: "https://stellife.notion.site/Simulate-a-Measurement-351c1bc0501c4bdaa70b7430bed9d292",
  },
  BIND_DEVICE: {
    url: "https://stellife.notion.site/Bind-a-Device-to-a-Hub-251f15d2493245288000168b5b862f84",
    description:
      "By binding a device to a hub, measurements taken with that device and received by a different hub will still be associated to the bound hub. ",
  },
  UNBIND_DEVICE: {
    url: "https://stellife.notion.site/Unbind-a-Device-from-a-Hub-66429905fd404c82a25baa366a81af05",
    description:
      "Unbind the devices from their bound hubs. If any of the devices are not bound, no changes will be made. ",
  },
  BLOCK_DEVICE: {
    url: "https://stellife.notion.site/Block-Devices-408ba7ff9eef42fb9d59cd372e6bf2b4",
    description:
      "Blocking a vitals device prevents its measurements from being sent to your systems and those measurements will not be visible in the dashboard. ",
  },
  UNBLOCK_DEVICE: {
    description:
      "Unblocking a previously blocked vitals device will allow measurements from it to be sent to your system. Any previously blocked measurements will not be resent.",
  },
  RESEND_MEASURE: {
    url: "https://stellife.notion.site/Resend-a-Measurement-ea361f7a680e4efcb3a97e6f9a3e3fed",
    description:
      "Resend measurements to endpoints, whether failed or successful. ",
  },
  SANDBOX_MODE: {
    url: "https://stellife.notion.site/Sandbox-09daba5e3cc24550a42d819dfc69adf3",
    description:
      "Sandbox mode allows you to test and explore Stel features without effecting the data and configurations in your production environment. ",
  },
  DEVICE_METRICS: {
    url: "https://stellife.notion.site/Device-Metrics-6b97087252d049a480de585750d85962",
  },
  VIRTUAL_HUB: {
    description:
      "Virtual hubs exist only in the sandbox and can be used to simulate measurements as if they came from a real hub. ",
    url: "https://stellife.notion.site/Hubs-2f01cacf425c45d49aca643d6197949c#797940e00f5f42ee9d3c9c0e82cdfa2d",
  },
  INVITE_USERS: {
    url: "https://stellife.notion.site/Invite-a-User-dfda9ab5b1af46de8e1c38a697d4244d",
  },
  INTEGRATION_GUIDE: {
    url: "https://stellife.notion.site/Integration-Guide-3ebb2ef69daf4ef8a019d581a0529102",
  },
  PAYLOAD_SCHEMA: {
    url: "https://stellife.notion.site/Stel-v2-0-045562f5b39b4dce9b7531bd9d1926a0",
  },
  BIND_SELECT_DEVICE: {
    url: "",
    description: "Creates a binding between this devices and this hub",
  },
  BLOCK_SELECT_DEVICE: {
    url: "",
    description: "Block this device for all hubs.",
  },
  DEVICE_HOLD: {
    url: "https://stellife.notion.site/Device-Hold-466b459fe07a414ab2c3822d08ab3952",
  },
  TROUBLESHOOT: {
    url: "https://stellife.notion.site/Hub-Troubleshooting-21c31ed634224253b4e009e8ddc5ba00",
    description:
      "Follow pre-built guides to troubleshoot and diagnose issues with a hub. ",
  },
  MEASURE_TIME: {
    url: "https://www.notion.so/stellife/Table-Reference-4fa1578a31da4e5bb890238f53e28351?pvs=4#e21833ab4b4d4725b6d6797d3a3d2a04",
  },
  HUB_HOLDING_DEVICES: {
    url: "https://www.notion.so/stellife/Device-Hold-466b459fe07a414ab2c3822d08ab3952?pvs=4#fed2d76a851b413c8ce99e95d16f1fe4",
    description:
      "All new devices communicating with this hub will be put on hold. ",
  },
  HUB_EXPECTING_DEVICES: {
    url: "https://www.notion.so/stellife/Device-Hold-466b459fe07a414ab2c3822d08ab3952?pvs=4#768a88d31297408da463d3c703ed662f",
    description:
      "Approved devices communicating with this hub will be automatically bound. ",
  },
  APPROVE_MEASURES: {
    url: "",
    description: "Approved measurements will be sent to the endpoint.",
  },
  REJECT_MEASURES: {
    url: "",
    description: "Rejected measurements will not be sent to the endpoint.",
  },
};

export const CARRIERS = [
  {
    label: "UPS",
    value: "ups",
  },
  {
    label: "Fedex",
    value: "fedex",
  },
  {
    label: "DHL",
    value: "dhl",
  },
  {
    label: "USPS",
    value: "usps",
  },
];

export const getURLTrackingShipping = (carrier, trackingNumber) => {
  switch (carrier) {
    case "dhl":
      return `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNumber}`;
    case "fedex":
      return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`;
    case "usps":
      return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`;
    default:
      return "";
  }
};
export const ORDER_STATUS = Object.freeze({
  CANCELLED: "Cancelled",
  PROCESSED: "Processed",
  CREATED: "Created",
  PENDING_CONFIRM: "Pending Confirmation",
  CONFIRMED: "Confirmed",
  SHIPPED: "Shipped",
  COMPLETE: "Complete",
  AWAITING_PO: "Awaiting PO",
});

export const SNACKBAR_STATUS = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

export const DeliveryStatus = Object.freeze({
  IN_PROGRESS: "in progress",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
});

export const OrgLicense = Object.freeze({
  ENTERPRISE: "Enterprise",
  PREPAID: "Prepaid",
  USAGE: "Usage",
});

export const HUB_RETURN_REASONS = Object.freeze({
  HARDWARE: "Hardware",
  SOFTWARE: "Software",
  CONNECTIVITY: "Cellular Connectivity",
  OTHER: "Other",
});

export const HUB_RETURN_DETERMINATIONS = Object.freeze({
  REPAIR: "Repair",
  REPLACE: "Replace",
  NOT_COVERED: "Not Covered",
});

export const HUB_RETURN_STATUS = Object.freeze({
  CREATED: "Created",
  SUBMITTED: "Submitted",
  RECEIVED: "Received",
  COMPLETED: "Completed",
});

export const MEASURE_TYPES = {
  bloodpressure: {
    label: "Blood Pressure",
    value: "bloodpressure",
    icon: <BloodPressure />,
    fields: {
      sys: {
        label: "Systolic",
        key: "sys",
        required: true,
        max: 9999,
        unit: "mmHg",
      },
      dia: {
        label: "Diastolic",
        key: "dia",
        required: true,
        max: 9999,
        unit: "mmHg",
      },
      bpm: {
        label: "BPM",
        key: "bpm",
        required: true,
        max: 9999,
        unit: "bpm",
      },
      irregular_pulse: {
        label: "Irregular Pulse",
        key: "irregular_pulse",
        required: false,
        options: [true, false],
        unit: null,
      },
    },
  },
  ekg: {
    label: "EKG",
    value: "ekg",
    icon: <Ekg />,
    fields: {
      irregular_pulse: {
        label: "Irregular Pulse",
        key: "irregular_pulse",
        required: false,
        options: [true, false],
        unit: null,
      },
    },
  },
  glucose: {
    label: "Glucose",
    value: "glucose",
    icon: <Glucose />,
    deviceType: "Glucometer",
    fields: {
      mg_dl: {
        label: "mg/dL",
        key: "mg_dl",
        required: true,
        max: 9999,
        unit: "mg/dL",
      },
    },
  },
  "isf-glucose": {
    label: "CGM/ISF Glucose",
    value: "isf-glucose",
    icon: <Glucose />,
    deviceType: "CGM",
    fields: {
      mg_dl: {
        label: "mg/dL",
        key: "mg_dl",
        required: true,
        max: 9999,
        unit: "mg/dL",
      },
    },
  },
  inr: {
    label: "INR",
    value: "inr",
    icon: <Inr />,
    deviceType: "INR Meter",
    fields: {
      inr: {
        label: "inr",
        key: "inr",
        required: true,
        max: 999,
        unit: null,
      },
      pt: {
        label: "pt",
        key: "pt",
        required: true,
        max: 999,
        unit: "sec",
      },
      // IMPORTANT: If control is null, then status MUST be null
      // see INRSerializer in serializers.py
      control: {
        label: "control",
        key: "control",
        required: false,
        options: ["high", "low", null],
        unit: null,
      },
      status: {
        label: "status",
        key: "status",
        required: false,
        options: ["pass", "fail", null],
        unit: null,
      },
    },
  },
  pillcap: {
    label: "Pillcap",
    value: "pillcap",
    icon: <Pillcap />,
    options: null,
  },
  pulseox: {
    label: "Pulse Oximeter",
    value: "pulseox",
    icon: <SPO2 />,
    fields: {
      spo2: {
        label: "SpO2",
        key: "spo2",
        required: true,
        max: 9999,
        unit: "%",
      },
      bpm: {
        label: "BPM",
        key: "bpm",
        required: true,
        max: 9999,
        unit: "bpm",
      },
    },
  },
  spirometer: {
    label: "Spirometry",
    value: "spirometer",
    icon: <Oximeter />,
    fields: {
      fvc: {
        label: "FVC",
        key: "fvc",
        required: false,
        max: 99,
        unit: "liters",
      },
      fev1: {
        label: "FEV1",
        key: "fev1",
        required: true,
        max: 99,
        unit: "liters",
      },
      pef: {
        label: "PEF",
        key: "pef",
        required: false,
        max: 999,
        unit: "L/min",
      },
      fev6: {
        label: "FEV6",
        key: "fev6",
        required: false,
        max: 99,
        unit: "liters",
      },
      fev1fev6: {
        label: "FEV1/FEV6",
        key: "fev1fev6",
        required: false,
        max: 1,
        unit: null,
      },
      fef2575: {
        label: "FEF2575",
        key: "fef2575",
        required: false,
        max: 99,
        unit: null,
      },
    },
  },
  temperature: {
    label: "Temperature",
    value: "temperature",
    icon: <Temperature />,
    deviceType: "Thermometer",
    fields: {
      celsius: {
        label: "Celsius",
        key: "temp",
        required: true,
        max: 9999,
        unit: "celsius",
      },
    },
  },
  wearable: {
    label: "Wearable",
    value: "wearable",
    icon: <Wearable />,
    fields: {
      // IMPORTANT: Neither are required but at least one is
      bpm: {
        label: "BPM",
        key: "bpm",
        required: false,
        max: 9999,
        unit: "bpm",
      },
      steps: {
        label: "Steps",
        key: "steps",
        required: false,
        max: 99999,
        unit: "steps",
      },
      temp: {
        label: "Temperature",
        key: "temp",
        required: true,
        max: 9999,
        unit: "celsius",
      },
      spo2: {
        label: "SpO2",
        key: "spo2",
        required: true,
        max: 9999,
        unit: "%",
      },
      pi: {
        label: "Perfusion Index",
        key: "pi",
        required: false,
        max: 100,
        unit: "%",
      },
      rr: {
        label: "Respiratory Rate",
        key: "rr",
        required: false,
        max: 999,
        unit: "breaths/min",
      },
      bp_wave: {
        label: "Blood Pulse Wave",
        key: "bp_wave",
        required: false,
        max: 99999,
        unit: null,
      },
      hrv: {
        label: "Heart Rate Variability",
        key: "hrv",
        required: false,
        max: 999,
        unit: "ms",
      },
      ambient_temp: {
        label: "Ambient Temperature",
        key: "ambient_temp",
        required: false,
        max: 99999,
        unit: "celsius",
      },
    },
  },
  weight: {
    label: "Weight",
    value: "weight",
    icon: <Weight />,
    deviceType: "Scale",
    fields: {
      weight_lbs: {
        label: "Weight",
        key: "weight_lbs",
        max: 9999,
        required: true,
        unit: "lbs",
      },
    },
  },
};

export const SANDBOX_MODE_MESSAGE =
  "You are currently in sandbox mode. To use this feature, please switch to production mode using the toggle on the left navigation bar.";

export const ENTERPRISE_FEATURE_MESSAGE = (
  <>
    This is an <strong>Enterprise feature</strong>. Please contact{" "}
    <strong>sales@stel.life</strong> to learn more.
  </>
);

export const DEVICE_STATE_STATUS = {
  INVENTORY: {
    label: "Inventory",
    color: "default",
    value: "INVENTORY",
  },
  REGISTERED: {
    label: "Registered",
    color: "primary",
    value: "REGISTERED",
  },
  RUNNING: {
    label: "Running",
    color: "warning",
    value: "RUNNING",
  },
  EXPIRED: {
    label: "Completed",
    color: "success",
    value: "EXPIRED",
  },
};
