import { Button, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FEATURE_FLAGS } from "../../utils/constants";
import { getFeatureFlag } from "../../utils/io";
import TrustedDeviceIcon from "../icons/TrustedDeviceIcon";
import MeasureList from "../MeasureList";
import TrustedDevice from "./TrustedDevice";

function RecentMeasures(props) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    loading,
    measures,
    measureSelection,
    setMeasureSelection,
    onChangeBindings,
    autoRefreshMeasure,
    setAutoRefreshMeasure,
    useTrustedDevices,
  } = props;

  const handleTrustedDevice = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Recent Device Measures</h2>
        {getFeatureFlag(FEATURE_FLAGS.TRUSTED_DEVICES) && useTrustedDevices && (
          <Button
            variant="contained"
            sx={{ height: "fix-content", gap: 1 }}
            onClick={handleTrustedDevice}
          >
            <TrustedDeviceIcon />
            <Typography>Trusted Devices</Typography>
          </Button>
        )}
      </Stack>
      <TrustedDevice
        isOpen={isOpen}
        handleTrustedDevice={handleTrustedDevice}
      />
      <MeasureList
        checkbox
        loading={loading}
        onSelectionChanged={setMeasureSelection}
        selectedMeasures={measureSelection}
        searchResults={measures}
        rows={measures}
        paginationMode="client"
        hideColumns={["hub_id", "device_make", "device_model"]}
        rowCount={measures?.length || 0}
        onChangeBindings={onChangeBindings}
        setAutoRefreshMeasure={setAutoRefreshMeasure}
        autoRefreshMeasure={autoRefreshMeasure}
      />
    </Stack>
  );
}

export default RecentMeasures;

RecentMeasures.propTypes = {
  loading: PropTypes.bool.isRequired,
  measures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  measureSelection: PropTypes.arrayOf(PropTypes.string).isRequired,
  setMeasureSelection: PropTypes.func.isRequired,
  onChangeBindings: PropTypes.func.isRequired,
  autoRefreshMeasure: PropTypes.bool.isRequired,
  setAutoRefreshMeasure: PropTypes.func.isRequired,
  useTrustedDevices: PropTypes.bool.isRequired,
};
