import { Box } from "@mui/material";
import React from "react";

function LoadingHub() {
  return (
    <Box
      sx={{
        position: "relative",
        width: 180,
        height: 180,
        borderRadius: "50%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&::after": {
          content: '""',
          position: "absolute",
          inset: "10%",
          borderRadius: "50%",
          background:
            "conic-gradient(from 180deg at 50% 50%, rgba(35, 121, 220, 0.60) 0deg, rgba(35, 121, 220, 0.00) 360deg)",
          animation: "loader-spin-reverse 1.5s linear infinite",
        },
        "@keyframes loader-spin-reverse": {
          "0%": { transform: "rotate(0deg)" },
          "100%": { transform: "rotate(-360deg)" },
        },
      }}
    >
      <Box
        sx={{
          width: "70px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 100,
          animation: "fadeSlow 1.5s ease-in-out infinite",
          "@keyframes fadeSlow": {
            "0%": { opacity: 0.6 },
            "50%": { opacity: 1 },
            "100%": { opacity: 0.6 },
          },
        }}
      >
        <img
          src="/stel-hub-solid-red.png"
          alt="LoadingHub"
          style={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
}

export default LoadingHub;
